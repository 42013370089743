<template>
  <div id="loading">
    <div v-if="loading" class="overlay">
      <div class="loader" />
    </div>
  </div>
</template>
<script setup lang="ts">
import './loading.scss'

const store = useStore()
const { loading } = storeToRefs(store)

</script>
